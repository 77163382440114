var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',_vm._g({class:[
		'is-' + _vm.type,
		{
			'is-fullwidth': _vm.fullwidth,
			'is-small': _vm.small
		}
	],attrs:{"title":_vm.title,"href":_vm.url,"disabled":_vm.disabled,"target":"_blank"}},_vm.$listeners),[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.type === 'text')?_c('span',[(!_vm.isDownload)?_c('BaseIcon',{attrs:{"name":"arrow","width":"11","height":"18","viewBox":"30 30 300 450"}},[_c('RightChevronIcon')],1):_c('BaseIcon',{attrs:{"id":"download-icon","name":"download","height":"1.5rem","width":"1.5rem","color":"var(--tertiary)","viewBox":"0 0 26 26"}},[_c('DownloadIcon')],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }