<template>
	<ValidationProvider :rules="validationRules" :name="title" tag="section" v-slot="{ errors }" slim>
		<label
			:for="id"
			:class="[
				'product',
				{
					'is-required': required,
					'is-selected': !required && selected && !hasError,
					'is-disabled': isDisabled,
					'display-only': displayOnly,
					'is-error': option.hasError
				}
			]"
		>
			<input
				:type="inputType"
				:id="id"
				:name="title"
				:value="optionValue"
				v-model="innerValue"
				:disabled="isDisabled"
			/>
			<p class="required" v-if="required && !displayOnly">REQUIRED</p>

			<section>
				<BaseIcon :name="iconName" width="2.8125rem" height="2.8125rem">
					<component :is="productIcon" />
				</BaseIcon>

				<div>
					<h1>{{ title }}</h1>
					<p v-html="description"></p>
				</div>
			</section>

			<BaseIcon
				v-if="!displayOnly"
				class="add-button"
				:name="selected ? 'Product Selected' : 'Product Unselected'"
			>
				<SelectedIcon v-if="selected" />
				<AddIcon v-else />
			</BaseIcon>
		</label>
	</ValidationProvider>
</template>

<script>
	import { ValidationProvider } from 'vee-validate';

	import { BaseIcon, AddIcon, CheckingIcon, SelectedIcon } from '@/components/icons';

	export default {
		name: 'ProductSelection',
		components: {
			ValidationProvider,
			BaseIcon,
			AddIcon,
			CheckingIcon,
			SelectedIcon
		},
		props: {
			value: {
				required: true
			},
			option: {
				required: true
			},
			optionValueProperty: {
				type: String,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			description: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				required: false
			},
			disabled: {
				type: Boolean,
				required: false
			},
			validationRules: {
				type: [String, Object],
				required: false
			},
			displayOnly: {
				type: Boolean,
				required: false
			},
			iconName: {
				type: String,
				require: false
			},
			hasError: {
				type: Boolean,
				require: false,
				default: false
			},
			inputType: {
				type: String,
				required: false,
				default: 'checkbox'
			}
		},
		computed: {
			id() {
				return `${this.title.replace(/\s/g, '')}-${this._uid}`;
			},
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			},
			isDisabled() {
				return this.required || this.disabled;
			},
			selected() {
				if (this.value.constructor === Array) {
					return (
						this.required ||
						this.value.some(option => {
							return option[this.optionValueProperty] === this.option[this.optionValueProperty];
						})
					);
				}

				return this.required || this.value[this.optionValueProperty] === this.option[this.optionValueProperty];
			},
			productIcon() {
				return () => import('@/components/icons').then(all => all[this.iconName]);
			},
			optionValue() {
				let option = this.option;

				if (!this.required && this.value.constructor === Array) {
					let selectedOption = this.value.find(
						option => option[this.optionValueProperty] === this.option[this.optionValueProperty]
					);

					if (selectedOption) {
						option = selectedOption;
					}
				}

				return option;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.product {
		position: relative;
		display: block;
		padding: 0.75rem;
		border: 2px solid var(--text-primary);
		border-radius: 3px;
		transition: background-color 0.5s, color 0.5s;
		color: var(--text-primary);
		cursor: pointer;

		&:not(:last-child) {
			margin-bottom: 0.75rem;
		}

		svg {
			transition: fill 0.5s;
		}

		&.is-required {
			border: 2px solid var(--tertiary);
			color: var(--tertiary);
		}

		&.is-selected {
			background-color: var(--primary);
			color: var(--white);
		}

		&.is-disabled {
			cursor: not-allowed;
		}

		&.display-only {
			cursor: default;
		}

		&.is-error {
			border: 2px solid var(--danger);
		}

		.required {
			position: absolute;
			top: 0.25rem;
			right: 0.25rem;
			font-size: 0.625rem;
			font-weight: 800;
			color: var(--primary);
		}

		input {
			position: absolute;
			left: -9999px;
		}

		section {
			display: flex;
			align-items: center;

			div {
				flex: 1;
				margin-left: 1rem;

				h1 {
					font-size: 0.85rem;
					font-weight: 700;
				}

				p {
					font-size: 0.85rem;
					font-weight: 500;
					padding-top: 0.25rem;
					padding-right: 0.5rem;
				}
			}
		}

		.add-button {
			position: absolute;
			bottom: 0.25rem;
			right: 0.25rem;
			height: 1.25rem;
			width: 1.25rem;
			color: var(--icon-primary);
		}

		&.is-required .add-button,
		&.is-selected .add-button {
			color: var(--success);
		}
	}
</style>
