import axios from '@/utils/axios';

export interface IHomePageConfigsResponse {
	displayLeavingModalToggle: boolean;
	resumeApplicationConfigToggle: boolean;
	leaveReasonConfigs: ILeaveReason[];
	siteAvailability: ISiteAvailability;
	xsrfToken: string;
	showEmailField: boolean;
	existingMemberFlowToggle: boolean;
	clientPhoneNumber: string;
	clientWebsite: string;
	clientLocationsWebsite: string;
	dynamicHyperlinks: IDynamicHyperlinkConfiguration[];
	dynamicHeaderConfigurations: IDynamicHeaderConfigurations[];
	reselectPageTitle: string;
	ineligibilityPageText: string;
	ineligibilityPageDeclinedReselectText: string;
	eDisclosuresConfirmationAttempts: number | null;
	eDisclosuresConfirmationRetryCadence: number | null;
	captchaConfigurations: any[] | null;
	captchaUseConfigurations: any[] | null;
	missingCoreInformationText: string;
	reviewPageTextTemplate: string;
	apyFinePrint: string;
	apyFinePrintDisplayPages: [];
}

export interface IState {
	key: 'StateModel';
	country: string;
	name: string;
	stateAbbreviation: string;
	stateId: number;
}
export interface IEmploymentPageConfigsResponse {
	occupations: IOccupation[];
	employmentStatuses: IEmploymentStatus[];
}
export interface IOccupation {
	occupationId: number;
	occupationName: string;
}

export interface IEmploymentStatus {
	name: string;
	shortName: string;
}

enum HyperlinkType {
	None = 0,
	OLBLoginLinkDesktop = 1,
	OLBLoginLinkMobile = 2
}
interface IDynamicHeaderConfigurations {
	id: number;
	route: string;
	enabled: boolean;
	desktopHeader: string;
	mobileHeader: string;
	baoHeader: string;
}
interface IDynamicHyperlinkConfiguration {
	hyperlinkType: HyperlinkType;
	hyperlinkHTML: string;
}

interface IIdentificationConfiguration {
	id: number;
	label: string;
	defaultIssuer: string;
}

interface ICountryConfiguration {
	countryId: number;
	name: string;
	code: string;
	currencyCode: string;
	ofac: boolean;
	iban: boolean;
	transferEligible: boolean;
	countryCode: string;
}

interface ITaxpayerIdConfiguration {
	id: number;
	displayText: string;
	displayOrder: number;
	enabled: boolean;
	isDefault: boolean;
	regexValidation: string;
}

export interface IPersonVerificationConfiguration {
	sdkKey: string;
	enabled: boolean;
}
export interface IIdentityConfigurationResponse {
	identificationConfigurations: IIdentificationConfiguration[];
	countryConfigurations: ICountryConfiguration[];
	taxpayerIdConfigurations: ITaxpayerIdConfiguration[];
}
export interface IEmploymentConfigurationResponse {
	occupations: IOccupationConfiguration[];
	employmentStatuses: IEmploymentStatusConfiguration[];
	allowManualOccupationEntry: boolean;
	showEmployerField: boolean;
}
export interface ISignConfigurationResponse {
	termsAndConditionsAgreement: string;
	signingAgreement: string;
	updateContactInformationMessage: string;
	hasOLBRegistration: boolean;
}
export interface IOccupationConfiguration {
	occupationSerial: number;
	occupationName: string;
}
export interface IEmploymentStatusConfiguration {
	name: string;
	shortName: string;
}
export interface ISiteAvailability {
	isMemberChannelDisabled: boolean;
	memberChannelDisabledMessage: string;
	isNonMemberChannelDisabled: boolean;
	nonMemberChannelMessage: string;
	isResumeAppChannelDisabled: boolean;
	resumeAppChannelDisabledMessage: string;
	isSSOChannelDisabled: boolean;
	sSOChannelDisabledMessage: string;
	isMemberAdvocateChannelDisabled: boolean;
	memberAdvocateChannelDisabledMessage: string;
}

export interface IDisclosurePageConfigsResponse {
	disclosureConfigurations: IDisclosure[];
	keystoneEStatementOptionEnabled: boolean;
	eStatementOptionConfigurations: IEStatementOption[];
}

export interface IDisclosurePageConfigsGetRequest {
	applicationToken: string;
	applicantToken: string;
	applicantType: number;
}

export interface IPrintConfigGetResponse {
	showOLBRegistration: boolean;
	showOLBLogin: boolean;
	showOLBRedirect: boolean;
	onlineMobileBankingDisclosure: string;
	onlineBankingLoginURL: string;
}

export interface IRegistrationConfigsResponse {
	onlineMobileBankingDisclosure: string;
}

export interface IPrintConfigGetRequest {
	applicationToken: string;
	applicantToken: string;
}

export interface IDisclosure {
	id: number;
	disclosureType: number;
	displayText: string;
	displayOrder: number;
	subText: string;
	applicantType: number;
	displayForProductConfigurationId: number | null;
	isOptional: boolean;
}

export interface IEStatementOption {
	id: number;
	name: string;
	displayText: string;
	isCHecked: boolean;
	enabled: boolean;
}

export interface IFundingConfigurationsResponse {
	enableReselectField: boolean;
	enableACHPayments: boolean;
	headerText: string;
	fundingEnabled: boolean;
}

export interface ILeaveReason {
	id: number;
	reason: string;
	externalLink: string;
}
const getStates = async (): Promise<IState[]> => {
	try {
		const {
			data: { states = [] }
		} = await axios.get<{ states: IState[] }>(`/api/v2/Configuration/States`);

		return states;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const getHomePageConfigurations = async (): Promise<IHomePageConfigsResponse> => {
	try {
		const { data } = await axios.get<IHomePageConfigsResponse>(`/api/v2/Configurations`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getEmploymentPageConfigurations = async (): Promise<IEmploymentConfigurationResponse> => {
	try {
		const { data } = await axios.get<IEmploymentConfigurationResponse>(`/api/v2/Employment/Configuration`);

		return data;
	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getIdentityPageConfigurations = async (): Promise<IIdentityConfigurationResponse> => {
	try {
		const { data } = await axios.get<IIdentityConfigurationResponse>(`/api/v2/Identification/Configurations`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getDisclosurePageConfigurations = async (request: IDisclosurePageConfigsGetRequest): Promise<IDisclosurePageConfigsResponse> => {
	try {
		const { data } = await axios.post<IDisclosurePageConfigsResponse>(`/api/v2/Disclosure/Configurations`, request);

		return data;
	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const getFundingPageConfigurations = async (): Promise<IFundingConfigurationsResponse> => {
	try {
		const { data } = await axios.get<IFundingConfigurationsResponse>(`/api/v2/Funding/Configurations`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getSignPageConfiguration = async (): Promise<ISignConfigurationResponse> => {
	try {
		const { data } = await axios.get<ISignConfigurationResponse>(`/api/v2/Sign/Configuration`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getPrintPageConfigurations = async (request: IPrintConfigGetRequest): Promise<IPrintConfigGetResponse> => {
	try {
		const { data } = await axios.post<IPrintConfigGetResponse>(`/api/v2/Print/Configurations`, request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getRegistrationPageConfigurations = async (): Promise<IRegistrationConfigsResponse> => {
	try {
		const { data } = await axios.get<IRegistrationConfigsResponse>(
			`/api/v2/OnlineBankingRegistration/Configurations`
		);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export {
	getHomePageConfigurations,
	getStates,
	getEmploymentPageConfigurations,
	getIdentityPageConfigurations,
	getDisclosurePageConfigurations,
	getFundingPageConfigurations,
	getSignPageConfiguration,
	getPrintPageConfigurations,
	getRegistrationPageConfigurations,
	HyperlinkType,
	IDynamicHeaderConfigurations,
	IDynamicHyperlinkConfiguration,
	IIdentificationConfiguration,
	ICountryConfiguration,
	ITaxpayerIdConfiguration
};
