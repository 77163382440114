<template>
	<a
		v-on="$listeners"
		:title="title"
		:class="[
			'is-' + type,
			{
				'is-fullwidth': fullwidth,
				'is-small': small
			}
		]"
		:href="url"
		:disabled="disabled"
		target="_blank"
	>
		{{ title }}
		<span v-if="type === 'text'">
			<BaseIcon v-if="!isDownload" name="arrow" width="11" height="18" viewBox="30 30 300 450">
				<RightChevronIcon />
			</BaseIcon>
			<BaseIcon
				v-else
				id="download-icon"
				name="download"
				height="1.5rem"
				width="1.5rem"
				color="var(--tertiary)"
				viewBox="0 0 26 26"
			>
				<DownloadIcon />
			</BaseIcon>
		</span>
	</a>
</template>

<script>
	export default {
		name: 'ButtonLink',
		props: {
			title: {
				type: String,
				required: true
			},
			type: {
				type: String,
				required: false,
				default: 'primary'
			},
			disabled: {
				type: Boolean,
				required: false
			},
			fullwidth: {
				type: Boolean,
				required: false
			},
			small: {
				type: Boolean,
				required: false
			},
			url: {
				type: String,
				required: true
			},
			isDownload: {
				type: Boolean,
				required: false
			}
		},
		components: {
			BaseIcon: () => import('@/components/icons').then(({ BaseIcon }) => BaseIcon),
			RightChevronIcon: () => import('@/components/icons').then(({ RightChevronIcon }) => RightChevronIcon),
			DownloadIcon: () => import('@/components/icons').then(({ DownloadIcon }) => DownloadIcon)
		}
	};
</script>

<style lang="scss" scoped>
	a {
		display: block;
		box-sizing: border-box;
		background-color: var(--primary);
		padding: 15px 45px;
		cursor: pointer;
		text-align: center;
		vertical-align: middle;
		font-size: 1.25rem;
		font-weight: 600;
		color: var(--white);
		transition: all 0.3s linear;
		text-decoration: none;
		border: 2px solid var(--primary);

		&:hover {
			background-color: transparent;
			border: 2px solid var(--primary);
			color: var(--primary);
		}

		&:disabled:hover,
		&:disabled,
		&[disabled] {
			border: 2px solid var(--disabled);
			background-color: var(--disabled);
			color: var(--lightGrey);
			cursor: not-allowed;
		}

		&.is-small {
			font-size: 1rem;
			padding: 15px !important;
		}

		&.is-secondary {
			background-color: var(--secondary);
			border: 2px solid var(--secondary);

			&:hover {
				background-color: transparent;
				border: 2px solid var(--secondary);
				color: var(--secondary);
			}
		}

		&.is-tertiary {
			background-color: var(--tertiary);
			border: 2px solid var(--tertiary);

			&:hover {
				background-color: transparent;
				border: 2px solid var(--tertiary);
				color: var(--tertiary);
			}
		}

		&.is-hollow {
			background-color: transparent;
			border: 2px solid var(--primary);
			color: var(--primary);

			&:hover {
				background-color: var(--primary);
				border: 2px solid var(--primary);
				color: var(--white);
			}
		}

		&.is-plain {
			display: block;
			padding: 15px 15px;
			margin: 0 auto;
			background-color: initial;
			border: none;
			color: var(--primary);
			font-size: 1.25rem;
			font-weight: 400;
			text-decoration: none;
			text-transform: initial;
			transform: opacity 0.5s;

			&:hover {
				opacity: 0.7;
			}
		}

		&.is-text {
			padding: 0;
			margin: 0;
			background-color: initial;
			border: none;
			color: var(--bodyCopy);
			font-size: 0.9375rem;
			font-weight: 600;
			text-decoration: none;

			svg {
				margin-left: 0.375rem;
				// transition: padding 0.3s linear;
			}

			// &:hover svg {
			// 	padding-left: 0.75rem;
			// }
		}

		&.is-fullwidth {
			padding: 15px 0;
			width: 100%;
		}
	}

	#download-icon {
		margin-left: 0.75rem;
	}
</style>
