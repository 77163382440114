export const IdentificationTypes = {
	NONE: 0,
	DRIVERSLICENSE: 1,
	STATEID: 2,
	PASSPORT: 3,
	MATRICULACONSULAR: 4
};

export const IdentificationTypesDescriptions = new Map([
	[IdentificationTypes.NONE, 'None'],
	[IdentificationTypes.DRIVERSLICENSE, "Driver's License"],
	[IdentificationTypes.STATEID, 'State Issued ID'],
	[IdentificationTypes.PASSPORT, 'Passport'],
	[IdentificationTypes.MATRICULACONSULAR, 'Matricula Consular']
]);
