import axios from '@/utils/axios';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

interface IOverdraftCoverage {
	id: number;
	productId: number;
	name: String;
	description: String;
	benefits: String;
	iconName: String;
}

interface ISetOverdraftCoveragesResponse {
	success: boolean;
}
interface IGetOverdraftCoveragesResponse {
	overdraftCoverages: IOverdraftCoverage[];
	overdraftFundingSourceCoverages: IOverdraftCoverage[];
	courtesyPayTitleText: String;
	overdraftTitleText: String;
	showA9Download: boolean;
}

interface ISetOverdraftCoverageRequest {
	applicationToken: string;
	overdraftCoverage: IOverdraftCoverage;
}

const getOverdraftCoveragesByProducts = async (): Promise<IGetOverdraftCoveragesResponse> => {
	try {
		const { data: response } = await axios.get<IGetOverdraftCoveragesResponse>(`/api/v2/Overdraft/Configurations`);

		return response;
	} catch (error) {
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const setOverdraftCoverage = async (request: ISetOverdraftCoverageRequest): Promise<ISetOverdraftCoveragesResponse> => {
	try {
		const { data } = await axios.put<ISetOverdraftCoveragesResponse>('/api/v2/Overdraft', request);

		return data;
	} catch (error) {
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const setOverdraftFundingSourceCoverage = async (
	request: ISetOverdraftCoverageRequest
): Promise<ISetOverdraftCoveragesResponse> => {
	try {
		const { data } = await axios.put<ISetOverdraftCoveragesResponse>('/api/v2/OverdraftFundingSource', request);

		return data;
	} catch (error) {
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
export {
	IOverdraftCoverage,
	IGetOverdraftCoveragesResponse,
	ISetOverdraftCoverageRequest,
	getOverdraftCoveragesByProducts,
	setOverdraftCoverage,
	setOverdraftFundingSourceCoverage
};
