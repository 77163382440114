var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"rules":_vm.validationRules,"name":_vm.title,"tag":"section","slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{class:[
			'product',
			{
				'is-required': _vm.required,
				'is-selected': !_vm.required && _vm.selected && !_vm.hasError,
				'is-disabled': _vm.isDisabled,
				'display-only': _vm.displayOnly,
				'is-error': _vm.option.hasError
			}
		],attrs:{"for":_vm.id}},[((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],attrs:{"id":_vm.id,"name":_vm.title,"disabled":_vm.isDisabled,"type":"checkbox"},domProps:{"value":_vm.optionValue,"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,_vm.optionValue)>-1:(_vm.innerValue)},on:{"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.optionValue,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],attrs:{"id":_vm.id,"name":_vm.title,"disabled":_vm.isDisabled,"type":"radio"},domProps:{"value":_vm.optionValue,"checked":_vm._q(_vm.innerValue,_vm.optionValue)},on:{"change":function($event){_vm.innerValue=_vm.optionValue}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],attrs:{"id":_vm.id,"name":_vm.title,"disabled":_vm.isDisabled,"type":_vm.inputType},domProps:{"value":_vm.optionValue,"value":(_vm.innerValue)},on:{"input":function($event){if($event.target.composing)return;_vm.innerValue=$event.target.value}}}),(_vm.required && !_vm.displayOnly)?_c('p',{staticClass:"required"},[_vm._v("REQUIRED")]):_vm._e(),_c('section',[_c('BaseIcon',{attrs:{"name":_vm.iconName,"width":"2.8125rem","height":"2.8125rem"}},[_c(_vm.productIcon,{tag:"component"})],1),_c('div',[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.description)}})])],1),(!_vm.displayOnly)?_c('BaseIcon',{staticClass:"add-button",attrs:{"name":_vm.selected ? 'Product Selected' : 'Product Unselected'}},[(_vm.selected)?_c('SelectedIcon'):_c('AddIcon')],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }