import { overdraftAPI } from '@/api';
import { IOverdraftCoverage, IGetOverdraftCoveragesResponse } from '@/api/_v2/overdraft';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';

export interface IOverdraftState {
	moduleLoaded: boolean;
	overdraftCoverages: IOverdraftCoverage[];
	overdraftFundingSourceCoverages: IOverdraftCoverage[];
	courtesyPayTitleText: String;
	overdraftTitleText: String;
	selectedCourtesyPayCoverage: IOverdraftCoverage | null;
	selectedOverdraftCoverage: IOverdraftCoverage | null;
	showA9Download: boolean;
}

const overdraft: Module<IOverdraftState, IRootState> = {
	namespaced: true,
	state: {
		moduleLoaded: false,
		overdraftCoverages: [],
		overdraftFundingSourceCoverages: [],
		courtesyPayTitleText: '',
		overdraftTitleText: '',
		selectedCourtesyPayCoverage: null,
		selectedOverdraftCoverage: null,
		showA9Download: false
	},
	mutations: {
		setData,
		resetState(state) {
			state.moduleLoaded = false;
			state.overdraftCoverages = [];
			state.overdraftFundingSourceCoverages = [];
			state.courtesyPayTitleText = '';
			state.overdraftTitleText = '';
			state.selectedCourtesyPayCoverage = null;
			state.selectedOverdraftCoverage = null;
			state.showA9Download = false;
		}
	},
	actions: {
		async getCoverages({ commit, state, rootState }): Promise<IGetOverdraftCoveragesResponse> {
			if (!state.moduleLoaded && rootState.products?.selectedProducts) {
				try {
					const {
						overdraftCoverages,
						overdraftFundingSourceCoverages,
						courtesyPayTitleText,
						overdraftTitleText,
						showA9Download
					} = await overdraftAPI.getOverdraftCoveragesByProducts();
					commit('setData', { objName: 'overdraftCoverages', data: overdraftCoverages });
					commit('setData', {
						objName: 'overdraftFundingSourceCoverages',
						data: overdraftFundingSourceCoverages
					});

					return {
						overdraftCoverages,
						overdraftFundingSourceCoverages,
						courtesyPayTitleText,
						overdraftTitleText,
						showA9Download
					};
				} catch (error) {
					throw error;
				}
			}

			return {
				overdraftCoverages: state.overdraftCoverages,
				overdraftFundingSourceCoverages: state.overdraftFundingSourceCoverages,
				courtesyPayTitleText: state.courtesyPayTitleText,
				overdraftTitleText: state.overdraftTitleText,
				showA9Download: state.showA9Download
			};
		},
		async updateCoverage({ commit, rootGetters }, selectedCoverage: IOverdraftCoverage): Promise<void> {
			try {
				let request = {
					applicationToken: rootGetters['application/applicationToken'],
					overdraftCoverage: selectedCoverage
				};
				await overdraftAPI.setOverdraftCoverage(request);

				commit('setData', { objName: 'selectedCourtesyPayCoverage', data: selectedCoverage });
			} catch (error) {
				throw error;
			}
		},
		async updateFundingSourceCoverage(
			{ commit, rootGetters },
			selectedCoverage: IOverdraftCoverage
		): Promise<void> {
			try {
				let request = {
					applicationToken: rootGetters['application/applicationToken'],
					overdraftCoverage: selectedCoverage
				};
				await overdraftAPI.setOverdraftFundingSourceCoverage(request);

				commit('setData', { objName: 'selectedOverdraftCoverage', data: selectedCoverage });
			} catch (error) {
				throw error;
			}
		},
		async overdraftEligibleProductSelected({ state, dispatch, rootState }): Promise<boolean> {
			//if there is a selected product with a id on overdraft option
			if (state.overdraftCoverages != null && !state.overdraftCoverages.length) {
				await dispatch('getCoverages');
			}

			if (!rootState.products) return false;
			const selectedProductIds = rootState.products.selectedProducts.map(
				selectedProduct => selectedProduct.productId
			);

			let hasOverdraftConfigs = state.overdraftCoverages && state.overdraftCoverages.length ? true : false;

			return hasOverdraftConfigs
				? state.overdraftCoverages.some(coverage => selectedProductIds?.includes(coverage.productId))
				: false;
		},
		async overdraftFundingSourceEligibleProductSelected({ state, dispatch, rootState }): Promise<boolean> {
			//if there is a selected product with a id on overdraft option
			if (state.overdraftFundingSourceCoverages != null && !state.overdraftFundingSourceCoverages.length) {
				await dispatch('getCoverages');
			}

			if (!rootState.products) return false;
			const selectedProductIds = rootState.products.selectedProducts.map(
				selectedProduct => selectedProduct.productId
			);

			let hasOverdraftFundingSourceConfigs =
				state.overdraftFundingSourceCoverages && state.overdraftFundingSourceCoverages.length ? true : false;

			return hasOverdraftFundingSourceConfigs
				? state.overdraftFundingSourceCoverages.some(coverage =>
						selectedProductIds?.includes(coverage.productId)
				  )
				: false;
		}
	},
	getters: {
		selectedCoverage: state => state.selectedCourtesyPayCoverage,
		selectedOverdraftFundingCoverage: state => state.selectedOverdraftCoverage,
		shouldShowA9Download: state => state.showA9Download
	}
};

export default overdraft;
