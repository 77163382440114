<template functional>
	<g>
		<path
			d="M13.3333 20.1333H2.66667C1.92917 20.1333 1.33333 19.5375 1.33333 18.8V2.79997C1.33333 2.06247 1.92917 1.46663 2.66667 1.46663H8V6.1333C8 7.23747 8.89583 8.1333 10 8.1333H14.6667V18.8C14.6667 19.5375 14.0708 20.1333 13.3333 20.1333ZM10 6.79997C9.63333 6.79997 9.33333 6.49997 9.33333 6.1333V1.48747C9.45 1.51663 9.55833 1.57497 9.64167 1.66247L14.4708 6.49163C14.5583 6.57913 14.6167 6.6833 14.6458 6.79997H10ZM2.66667 0.133301C1.19583 0.133301 0 1.32913 0 2.79997V18.8C0 20.2708 1.19583 21.4666 2.66667 21.4666H13.3333C14.8042 21.4666 16 20.2708 16 18.8V6.96247C16 6.4333 15.7875 5.92497 15.4125 5.54997L10.5875 0.720801C10.2125 0.345801 9.70417 0.133301 9.175 0.133301H2.66667ZM8.66667 10.1333C8.66667 9.76663 8.36667 9.46663 8 9.46663C7.63333 9.46663 7.33333 9.76663 7.33333 10.1333V15.1916L5.1375 12.9958C4.87917 12.7375 4.45417 12.7375 4.19583 12.9958C3.9375 13.2541 3.9375 13.6791 4.19583 13.9375L7.52917 17.2708C7.7875 17.5291 8.2125 17.5291 8.47083 17.2708L11.8042 13.9375C12.0625 13.6791 12.0625 13.2541 11.8042 12.9958C11.5458 12.7375 11.1208 12.7375 10.8625 12.9958L8.66667 15.1916V10.1333Z"
			fill="#646464"
		/>
	</g>
</template>

<script>
	export default {
		name: 'DownloadIcon'
	};
</script>
