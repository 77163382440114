<template>
    <div class="scrollable-text" v-html="displayText"></div>
</template>

<script>
    export default {
        name: 'ScrollableText',
        props: {
            displayText: {
                type: String,
                required: true,
            }
        }

        //data() {
        //    return {
        //        text: '&#185;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas porttitor purus ligula, vel luctus ante pulvinar sit amet.'
        //    };
        //}
    }
</script>

<style lang="scss" scoped>
    .scrollable-text {
        margin: .182rem .5rem .182rem .5rem;
        overflow: auto;
        max-height: 4.5rem;
        background-color: var(--lightGrey-25);
        font: normal .85rem var(--text-body);
        border-radius: 5px;
        border: solid 1px var(--lightGrey-50);
        scrollbar-color: var(--lightGrey-75) var(--lightGrey-25);
        scrollbar-width: thin;
        scrollbar-gutter: stable both-edges;
    }
</style>