import axios from '@/utils/axios';

export interface IIdentification {
	identificationValue: string;
	issuer: string;
	issueDate: string;
	expirationDate: string;
	identificationType: number;
}
export interface IIdentificationByApplicantGetRequest {
	applicationToken: string;
	applicantToken: string;
}
export interface IIdentificationByApplicantGetResponse {
	identification: IIdentification;
}
export interface IIdentificationCreateRequest {
	applicationToken: string;
	applicantToken: string;
	ssn: string;
	memberType: number;
	identification: IIdentification;
	isCoApplicant: boolean;
}
export interface IIdentificationCreateResponse {
	identificationId: number;
	isExistingMember: boolean;
	duplicateApplicationType: number;
}
export interface IIdentificationUpdateRequest {
	applicationToken: string;
	identificationId: number;
	identification: IIdentification;
}
export interface IIdentificationUpdateResponse {
	updated: boolean;
}
export interface IApplicantIdentificationUpdateRequest {
	applicationToken: string;
	applicantToken: string;
	identificationId: number;
	dateOfBirth: string;
	ssn: string;
	last4SSN: string;
	taxpayerIdConfigurationId: number;
}
export interface IApplicantIdentificationUpdateResponse {
	updated: boolean;
	isValidSSN: boolean;
}

const getIdentificationByApplicantAsync = async (request: IIdentificationByApplicantGetRequest): Promise<IIdentificationByApplicantGetResponse> => {
	try {

		const { data } = await axios.post<IIdentificationByApplicantGetResponse>(`/api/v2/Identification/Applicant/Identification`, request);

		return data;

	} catch (error) {
	

		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const createIdentificationAsync = async (request: IIdentificationCreateRequest): Promise<IIdentificationCreateResponse> => {
	try {
		const { data } = await axios.post<IIdentificationCreateResponse>(`/api/v2/Identification`, request);

		return data;

	} catch (error) {
	

		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const updateIdentificationAsync = async (request: IIdentificationUpdateRequest): Promise<IIdentificationUpdateResponse> => {
	try {

		const { data } = await axios.put<IIdentificationUpdateResponse>(`/api/v2/Identification`, request);

		return data;

	} catch (error) {
	

		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const updateApplicantIdentificationDataAsync = async (request: IApplicantIdentificationUpdateRequest): Promise<IApplicantIdentificationUpdateResponse> => {
	try {

		const { data } = await axios.put<IApplicantIdentificationUpdateResponse>(`/api/v2/Identification/Applicant`, request);

		return data;

	} catch (error) {
	

		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};



export { getIdentificationByApplicantAsync, createIdentificationAsync, updateIdentificationAsync, updateApplicantIdentificationDataAsync };