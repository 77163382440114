import axios from '@/utils/axios';
import { IIdentificationCreateRequest } from '@/api/_v2/identification';
import { IApplicantContactData, IApplicantDataUpdateResponse } from '@/api/_v2/applicant';

export interface ISimulatedUpdateErrorResponse {
	errorMessage: string;
	errorStatusCode: number;
}

export interface ISimulatedUpdateResponse {
	errors: ISimulatedUpdateErrorResponse[];
}

const simulateCreateIdentification = async (
	request: IIdentificationCreateRequest
): Promise<ISimulatedUpdateResponse> => {
	try {
		const { data } = await axios.post(`api/v2/SimulatedUpdate/CreateIdentification`, request);

		return data;
	} catch (error) {
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

export interface ISimulateCreatePhoneRequest {
	applicationToken: string;
	applicantToken: string;
	applicantContactData: IApplicantContactData;
}

const simulateCreatePhone = async (request: ISimulateCreatePhoneRequest): Promise<ISimulatedUpdateResponse> => {
	try {
		const { data } = await axios.post(`api/v2/SimulatedUpdate/CreatePhone`, request);

		return data;
	} catch (error) {
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

export default {
	simulateCreateIdentification,
	simulateCreatePhone
};

export { simulateCreateIdentification, simulateCreatePhone };
