<template>

    <v-popover
        :trigger="triggerType"
        :placement="position"
        :offset="offset"
    >
        <p v-html="triggerContent"></p>

        <template #popover>
            <div :class="[ 'outer-tooltip', { 'is-small': isSmall }]">
                <section class="tooltip">
                    <h3>{{ title }}</h3>
                    <p v-html="description"></p>
                </section>
            </div>
        </template>

    </v-popover>

</template>




<script>

    export default {

        name: 'Tooltip',
        props: {

            triggerContent: {
                type: String,
                required: false,
                default: '?'
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            isSmall: {
                type: Boolean,
                required: false
            },
            offset: {
                type: [ Number, String ],
                required: false,
                default: 5
            },
            position: {
                type: String,
                required: false,
                default: 'auto'
            },
            triggerType: {
                type: String,
                required: false,
                default: 'hover click focus'
            }

        }

    }

</script>





<style lang="scss" scoped>

    ::v-deep .trigger {
        text-align: center;
        font-size: 0.725rem;
        font-weight: 400;
        height: 1rem;
        width: 1rem;
        border: 1px solid var(--primary);
        border-radius: 100%;
        background-color: var(--primary);
        color: var(--white);
        transition: background-color 0.5s, color 0.5s;
        cursor: pointer;

        //p { margin-top: -0.0625rem; }

        &:hover {
            background-color: transparent;
            color: var(--primary);
        }

    }

    .outer-tooltip {
        max-width: 22rem;

        .tooltip {
            text-align: center;
            padding: 0.75rem 0.25rem;
            border: 2px solid var(--tertiary);
            border-radius: 7px;
            background-color: var(--white);
            box-sizing: border-box;
            max-width: 100vw;

            h3 {
                color: var(--text-primary);
                font-size: 1rem;
                line-height: 1.125rem;
                margin-bottom: 1rem;
            }

            p {
                color: var(--darkGrey);
                font-size: 1rem;
                line-height: 1.4375rem;
                font-weight: 300;
            }

        }
        
        &.is-small { width: 11.25rem; }

    }

</style>