<template>
    <div class="input-container">
        <header class="input-header">
            <label :class="{ 'has-error': error }" :for="inputId" data-test-label>
                {{ label }}
            </label>

            <OaoTooltip v-if="tooltip" 
                        :description="tooltipDescription"
                        :title="tooltipLabel" />
        </header>

        <div class="segmented-container">
            <div v-for="(option, index) in sortedOptions"
                 :key="option.label"
                 :value="option.value"
                 :disabled="option.disabled"
                 :class="['option', {
                    'selected': innerValue === option.value
                 }]">

                <input v-model="innerValue" 
                       type="radio" 
                       :id="`${name}-${index}`" 
                       :name="`${name}`" 
                       :value="option.value" 
                       checked />
                <label 
                    :for="`${name}-${index}`">
                    {{ option.label }} 
                </label>
            </div>
        </div>
    </div>
</template>




<script>

    import { ValidationProvider } from 'vee-validate';

    import { mapGetters } from "vuex";

    export default {

        name: 'SegmentedControl',
        components: {
            ValidationProvider,
            OaoTooltip: () => import("@/components/OaoTooltip"),
        },
        props: {
            value: {
                required: true,
            },
            error: {
                type: String,
                required: false
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: false
            },
            options: {
                type: Array,
                required: true
            },
            tooltip: {
                type: Boolean,
                required: false,
                default: null,
            },
            tooltipLabel: {
                type: String,
                required: false
            }, 
            tooltipDescription: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                sortedOptions: []
            }
        },
        async created() {
            this.sortedOptions = this.options.sort((x, y) => x.displayOrder - y.displayOrder);
        },
        computed: {
            inputId() {
                return (
                    this.id || `${this.label.replace(/\s/g, "")}-${this._uid}`
                );
            },
            innerValue: {
                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }
            },

        }

    }

</script>




<style lang="scss" scoped>

    .input-container {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        .has-error {
            color: var(--danger);
        }

        .input-header {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: baseline;

            label {
                display: block;
                color: var(--text-primary);
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                text-transform: uppercase;
            }
        }

        .segmented-container {
            border-radius: 3px;
            background-color: var(--background);
            border: 1px solid #C9C9C9;
            display: flex;
            flex-direction: row;
            box-shadow: inset 0px 4px 4px 0px rgba(67, 67, 67, 0.10);

            .option {
                flex: 1;
                padding: 0.25rem;
                margin: 0.1rem;

                &.selected {
                    background-color: var(--primary);
                    border-radius: 3px;
                    color: var(--white);
                }

                &:not(.selected) {
                    color: var(--primary);
                }

                label {
                    text-align: center;
                    font-size: 0.8rem;
                    font-weight: 600;
                    width: 100%;
                    display: inline-block;
                    cursor: pointer;
                    vertical-align: middle;
                    height:100%;
                }

                input {
                    display: none;
                }
            }
        }
    }




</style>