import { configurationsAPIv2 } from '@/api';
import { IDisclosure, IDisclosurePageConfigsResponse, IEStatementOption } from '@/api/_v2/configurations';
import { ApplicantTypes } from '@/constants';

export interface IDisclosureConfigurationState {
	disclosureConfiguration: IDisclosureConfiguration;
}

export interface IDisclosureConfiguration {
	primaryDisclosures: IDisclosure[];
	coapplicantDisclosures: IDisclosure[];
	isKeystoneEStatement: boolean;
	eStatementOptionConfigurations: IEStatementOption[];
}

const state: IDisclosureConfigurationState = {
	disclosureConfiguration: {
		primaryDisclosures: [],
		coapplicantDisclosures: [],
		isKeystoneEStatement: false,
		eStatementOptionConfigurations: []
	}
};

const mutations = {
	//TODO: Add Mutations (if any)
};

function resetState(state) {
	state.disclosureConfiguration.primaryDisclosures = [],
	state.disclosureConfiguration.coapplicantDisclosures = [],
	state.disclosureConfiguration.eStatementOptionConfigurations = [],
	state.disclosureConfiguration.isKeystoneEStatement = false
}

const actions = {
	async getDisclosureConfigurations({ commit, rootState, state }: any, isCoApplicant: boolean): Promise<IDisclosurePageConfigsResponse> {
		try {
			let primary;
			let coapplicant;
			let isKeystoneEStatement = false;
			let eStatementOptionConfigurations;

			if (isCoApplicant) {
				const coApplicantRequest = {
					applicationToken: rootState.application.applicationToken,
					applicantToken: rootState.coApplicant.applicantToken,
					applicantType: ApplicantTypes.COAPPLICANT
				};
				const coApplicantonfigs = await configurationsAPIv2.getDisclosurePageConfigurations(coApplicantRequest);
				coapplicant = coApplicantonfigs.disclosureConfigurations;
				isKeystoneEStatement = coApplicantonfigs.keystoneEStatementOptionEnabled;
				eStatementOptionConfigurations = coApplicantonfigs.eStatementOptionConfigurations;
			} else {
				const applicantRequest = {
					applicationToken: rootState.application.applicationToken,
					applicantToken: rootState.applicant.applicantToken,
					applicantType: ApplicantTypes.PRIMARYAPPLICANT
				};
				const applicantConfigs = await configurationsAPIv2.getDisclosurePageConfigurations(applicantRequest);
				primary = applicantConfigs.disclosureConfigurations;
				isKeystoneEStatement = applicantConfigs.keystoneEStatementOptionEnabled;
				eStatementOptionConfigurations = applicantConfigs.eStatementOptionConfigurations;
			}

			commit('setData', {
				objName: 'disclosureConfiguration',
				data: { primaryDisclosures: primary, coapplicantDisclosures: coapplicant, isKeystoneEStatement: isKeystoneEStatement, eStatementOptionConfigurations: eStatementOptionConfigurations }
			});

			return state.disclosureConfiguration;
		} catch (error) {
			
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

export { state, resetState, mutations, actions };

export default {
	state,
	resetState,
	actions,
	mutations
};
