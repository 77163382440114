<template functional>
	<g>
		<path
			d="M12.5 25.4165C15.8152 25.4165 18.9946 24.0995 21.3388 21.7553C23.683 19.4111 25 16.2317 25 12.9165C25 9.6013 23.683 6.42187 21.3388 4.07767C18.9946 1.73346 15.8152 0.416504 12.5 0.416504C9.18479 0.416504 6.00537 1.73346 3.66117 4.07767C1.31696 6.42187 0 9.6013 0 12.9165C0 16.2317 1.31696 19.4111 3.66117 21.7553C6.00537 24.0995 9.18479 25.4165 12.5 25.4165ZM10.5469 16.8228H11.7188V13.6978H10.5469C9.89746 13.6978 9.375 13.1753 9.375 12.5259C9.375 11.8765 9.89746 11.354 10.5469 11.354H12.8906C13.54 11.354 14.0625 11.8765 14.0625 12.5259V16.8228H14.4531C15.1025 16.8228 15.625 17.3452 15.625 17.9946C15.625 18.644 15.1025 19.1665 14.4531 19.1665H10.5469C9.89746 19.1665 9.375 18.644 9.375 17.9946C9.375 17.3452 9.89746 16.8228 10.5469 16.8228ZM12.5 6.6665C12.9144 6.6665 13.3118 6.83112 13.6049 7.12415C13.8979 7.41717 14.0625 7.8146 14.0625 8.229C14.0625 8.6434 13.8979 9.04083 13.6049 9.33386C13.3118 9.62688 12.9144 9.7915 12.5 9.7915C12.0856 9.7915 11.6882 9.62688 11.3951 9.33386C11.1021 9.04083 10.9375 8.6434 10.9375 8.229C10.9375 7.8146 11.1021 7.41717 11.3951 7.12415C11.6882 6.83112 12.0856 6.6665 12.5 6.6665Z"
		/>
	</g>
</template>

<script>
	export default {
		name: 'InformationIcon'
	};
</script>
